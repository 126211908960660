import {PersonSex} from "@/constants/PersonConstants";
import {
    CitizenPassportDataDTO,
    EADDataDTO,
    GreenCardDataDTO,
    SSNCardDataDTO, StateIDDataDTO
} from "@/dto/request/rer/CompleteREREmployeePayload";

export default class CompleteRIDCContractorPayload {

    personId: number | null = null;
    firstName: string | null = null;
    lastName: string | null = null;
    sex: PersonSex | null = null;
    countryOfBirth: string | null = null;
    dateOfBirth: string | null = null;
    nationality: string | null = null;
    contractStart: Date | null = null;
    ead?: EADDataDTO | null = null;
    greenCard?: GreenCardDataDTO | null = null;
    passport?: CitizenPassportDataDTO | null = null;
    ssn = new SSNCardDataDTO();
    stateID = new StateIDDataDTO();

    constructor(init?: Partial<CompleteRIDCContractorPayload>) {
        Object.assign(this, init);
    }

}
