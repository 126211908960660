




















import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import RIDCService from "@/services/request/RIDCService";
import RIDC_DTO from "@/dto/request/ridc/RIDC_DTO";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import { RIDCContractorStatus } from "@/constants/request/RIDCConstants";
import { processError } from "@/utils/ComponentUtils";
import ContractorRIDC from "@/components/request/ridc/ContractorRIDC.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  computed: {
    RIDCContractorStatus() {
      return RIDCContractorStatus
    },
  },
  components: { ContractorRIDC, PortalCollapse}
})
export default class RIDC extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Auth.Getter
  private isAdmin!: boolean;

  request: RIDC_DTO | null = null;

  mounted(){
    this.loadRequest();
  }

  get id(){
    return Number.parseInt(this.$route.params.id);
  }

  loadRequest(){
    this.startLoading();
    RIDCService.getById(this.id).then(
        ok => {
          this.request = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

}
